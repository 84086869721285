import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Input,
  Typography,
  Divider,
  Button,
  InputNumber,
  Select,
  message,
  Checkbox,
  notification,
} from 'antd';
import {
  MinusCircleTwoTone,
  PlusOutlined,
  DownCircleTwoTone,
  UpCircleTwoTone,
} from '@ant-design/icons';
import ImageUpload from '../shared/image-upload/image-upload.component';
import VideoUpload from '../shared/video-upload/video-upload.component';
import QuestionDependency from '../question-dependency/question-dependency.component';

import { getBase64 } from '../../utils/base64';

import './question-drawer.style.css';

const { Title, Text } = Typography;
const { Option } = Select;

const AnswerItem = ({
  answer,
  handleChange,
  handleRemove,
  handleOrder,
  questionType,
}) => {
  return (
    <div className="drawer-answer-item">
      <div className="drawer-answer-item__answer">
        <Input
          placeholder="Válasz"
          value={answer.content}
          onChange={e =>
            handleChange({
              field: 'content',
              value: e.target.value,
              order: answer.order,
            })
          }
        />

        <UpCircleTwoTone
          className="drawer-answer-item__icon"
          onClick={() =>
            handleOrder({ oldOrder: answer.order, newOrder: answer.order - 1 })
          }
        />
        <DownCircleTwoTone
          className="drawer-answer-item__icon"
          onClick={() =>
            handleOrder({ oldOrder: answer.order, newOrder: answer.order + 1 })
          }
        />
        <MinusCircleTwoTone
          className="drawer-answer-item__icon"
          twoToneColor="#eb2f96"
          onClick={() => handleRemove({ order: answer.order })}
        />
      </div>
      {questionType === 'multichoice' && (
        <div className="drawer-answer-item__check">
          <Checkbox
            checked={answer.exclusive}
            onChange={e => {
              handleChange({
                field: 'exclusive',
                value: e.target.checked,
                order: answer.order,
              });
            }}
          >
            Kizárólagos
          </Checkbox>
        </div>
      )}
      {questionType !== 'ordered' && questionType !== 'slider' && (
        <div className="drawer-answer-item__check">
          <Checkbox
            checked={answer.isRight}
            onChange={e => {
              handleChange({
                field: 'isRight',
                value: e.target.checked,
                order: answer.order,
              });
            }}
          >
            Helyes
          </Checkbox>
        </div>
      )}
      {questionType !== 'ordered' && questionType !== 'slider' && (
        <>
          <div className="drawer-answer-item__image">
            <Text>Videó:</Text>
            <VideoUpload
              onVideoLoaded={file => {
                getBase64(file, result => {
                  handleChange({
                    field: 'video',
                    value: result,
                    order: answer.order,
                  });
                });
              }}
              url={answer.video}
              handleDelete={() => {
                handleChange({
                  field: 'video',
                  value: null,
                  order: answer.order,
                });
              }}
            />
          </div>
          <div className="drawer-answer-item__image">
            <Text>Kép:</Text>
            <ImageUpload
              onImageLoaded={file => {
                getBase64(file, result => {
                  handleChange({
                    field: 'image',
                    value: result,
                    order: answer.order,
                  });
                });
              }}
              url={answer.image}
              handleDelete={() => {
                handleChange({
                  field: 'image',
                  value: null,
                  order: answer.order,
                });
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

const QuestionDrawer = ({
  isOpen = false,
  onClose = () => {},
  handleSubmit = () => {},
  initialQuestion = {},
  tagList,
  loading,
  questions,
}) => {
  const [answers, setAnswers] = useState(initialQuestion.answers || []);
  const [type, setType] = useState(initialQuestion.type || 'normal');
  const [questionTags, setQuestionTags] = useState([]);
  const [questionImageBase64, setQuestionImageBase64] = useState(null);
  const [questionVideoBase64, setQuestionVideoBase64] = useState(null);
  const [content, setContent] = useState(initialQuestion.content || '');
  const [image, setImage] = useState(initialQuestion.image);
  const [video, setVideo] = useState(initialQuestion.video);
  const [point, setPoint] = useState(initialQuestion.point || 1);
  const [maxAnswerCount, setMaxAnswerCount] = useState(
    initialQuestion.maxAnswerCount || 1
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [random, setRandom] = useState(initialQuestion.random);
  const [hideresult, setHideresult] = useState(initialQuestion.hideresult);
  const [webFeatured, setWebFeatured] = useState(initialQuestion.webFeatured);
  const [questionDependencies, setQuestionDependencies] = useState([]);
  const [clearedDependencies, setClearedDependencies] = useState([]);
  const [randomLimit, setRandomLimit] = useState(
    initialQuestion.randomLimit || 1
  );
  const [copiedAnswers, setCopiedAnswers] = useState([]);

  const clearState = () => {
    setQuestionTags([]);
    setAnswers([]);
    setContent('');
    setPoint(1);
    setMaxAnswerCount(1);
    setQuestionImageBase64(null);
    setImage(null);
    setQuestionVideoBase64(null);
    setVideo(null);
    setRandom(false);
    setType('normal');
    setQuestionDependencies([]);
  };
  useEffect(() => {
    if (Object.keys(initialQuestion).length > 0) {
      setAnswers(initialQuestion.answers);
      setContent(initialQuestion.content);
      setPoint(initialQuestion.point);
      setType(initialQuestion.type);
      setImage(initialQuestion.image);
      setVideo(initialQuestion.video);
      setRandom(initialQuestion.random);
      setHideresult(initialQuestion.hideresult);
      setWebFeatured(initialQuestion.webFeatured);
      setMaxAnswerCount(initialQuestion.maxAnswerCount);
      setQuestionDependencies(initialQuestion.dependencies);
      setRandomLimit(initialQuestion.randomLimit);
      // get the selected id-s
      if (initialQuestion.tags) {
        setQuestionTags(initialQuestion.tags.map(tag => tag.content));
      }
    } else {
      clearState();
    }
  }, [initialQuestion, isOpen]);

  useEffect(() => {
    const clipBoardAnswers = localStorage.getItem('answer_clipboard');
    if (clipBoardAnswers) {
      setCopiedAnswers(JSON.parse(clipBoardAnswers));
    }
  }, []);

  const handleAddDependency = () => {
    setQuestionDependencies([...questionDependencies, questions[0]]);
  };

  const answerChange = ({ value, order, field }) => {
    setAnswers(
      answers.map((answer, index) => {
        if (index === order) {
          return { ...answer, [field]: value };
        }
        return { ...answer };
      })
    );
  };

  const copyAnswers = () => {
    const answersToCopy = answers.map(({ content, order }) => {
      return {
        content,
        order,
      };
    });
    localStorage.setItem('answer_clipboard', JSON.stringify(answersToCopy));
    notification.open({
      message: 'Válaszok másolva',
    });
  };

  const injectAnswers = () => {
    setAnswers([...copiedAnswers]);
  };

  useEffect(() => {
    if (!initialQuestion.maxAnswerCount) {
      setMaxAnswerCount(answers.length);
    }
    if (!initialQuestion.randomLimit) {
      setRandomLimit(answers.length);
    }
  }, [answers, initialQuestion.maxAnswerCount]);

  const answerOrder = ({ oldOrder, newOrder }) => {
    if (newOrder < 0 || newOrder > answers.length) {
      return;
    }
    setAnswers(
      answers
        .map((answer, index) => {
          if (answer.order === newOrder) {
            return { ...answer, order: oldOrder };
          } else if (answer.order === oldOrder) {
            return { ...answer, order: newOrder };
          }
          return { ...answer };
        })
        .sort((a, b) => a.order - b.order)
    );
  };
  const answerRemove = ({ order }) => {
    setAnswers(
      answers
        .filter(answer => answer.order !== order)
        .map((answer, index) => {
          return { ...answer, order: index };
        })
    );
  };
  const submitQuestion = () => {
    if (!content) {
      message.error('Kérdés mező nem lehet üres');
      return;
    }
    if (
      answers &&
      answers.find(answer => !answer.content && !answer.image && !answer.video)
    ) {
      message.error('Üres válasz nem vehető fel');
      return;
    }
    if (point < 0) {
      message.error('A pont értéke nem lehet negatív');
      return;
    }
    const question = {
      content,
      type,
      point,
      maxAnswerCount,
      tags: questionTags,
      answers,
      random,
      randomLimit,
      hideresult: hideresult ?? false,
      webFeatured: webFeatured,
      dependencies: clearedDependencies,
      image: questionImageBase64 ? questionImageBase64 : image,
      video: questionVideoBase64 ? questionVideoBase64 : video,
    };
    handleSubmit({ ...initialQuestion, ...question }, clearState);
  };

  const handleTagChange = value => {
    setQuestionTags(value);
  };

  const handleQuestionDependencyChange = (val, index) => {
    const result = [...questionDependencies];
    result[index] = val;
    setQuestionDependencies(result);
  };

  const handleQuestionDependencyAnswerChange = (selectedAnswer, index) => {
    const result = JSON.parse(JSON.stringify(questionDependencies));
    result[index].dependency_answer_uuid = selectedAnswer.uuid;
    result[index].dependency_answer_id = selectedAnswer.id;
    setQuestionDependencies(result);
  };

  const handleDependencySave = () => {
    const result = questionDependencies.map(questionDependency => ({
      questionId:
        questionDependency.dependency_question_id || questionDependency.id,
      answerId:
        questionDependency.dependency_answer_id ||
        questionDependency.answers.find(
          answer => answer.uuid === questionDependency.dependency_answer_uuid
        )?.id,
    }));
    setClearedDependencies(result);
    setIsModalVisible(false);
  };

  const handleDependencyDelete = dependency => {
    setQuestionDependencies(
      questionDependencies.filter(
        dep => dep.dependency_question_id !== dependency.dependency_question_id
      )
    );
  };

  return (
    <Drawer
      width={'80%'}
      placement="right"
      closable={true}
      maskClosable={false}
      onClose={onClose}
      visible={isOpen}
      className="question-drawer"
      data-testid="question-drawer"
    >
      <div className="question-container">
        <Title level={4}>Kérdés</Title>
        <Input
          placeholder="Kérdés"
          value={content}
          onChange={e => setContent(e.target.value)}
        />
        <br />
        <Text>Típus:</Text>
        <Select
          placeholder="Kérem válaszon"
          onChange={value => setType(value)}
          value={type}
        >
          <Option value="normal">Normál</Option>
          <Option value="multichoice">Több válasz</Option>
          <Option value="custom">Szabadszavas</Option>
          <Option value="ordered">Rangsorolós</Option>
          <Option value="slider">Csúszkás</Option>
        </Select>
        <br />
        <Text>Kép:</Text>
        <ImageUpload
          onImageLoaded={file =>
            getBase64(file, result => setQuestionImageBase64(result))
          }
          url={image}
          handleDelete={() => {
            setImage(null);
            setQuestionImageBase64(null);
          }}
        />
        <br />
        <Text>Videó:</Text>
        <VideoUpload
          onVideoLoaded={file =>
            getBase64(file, result => setQuestionVideoBase64(result))
          }
          url={video}
          handleDelete={() => {
            setVideo(null);
            setQuestionVideoBase64(null);
          }}
        />
        <br />
        <Text>Pont:</Text>
        <InputNumber
          min={1}
          value={point}
          onChange={value => setPoint(value)}
        />
        <Button
          onClick={() => {
            setIsModalVisible(true);
          }}
          disabled={questions?.length === 0}
          className="question-drawer__dependency-button"
        >
          Függőségek
        </Button>
        <QuestionDependency
          isModalVisible={isModalVisible}
          handleOk={() => {}}
          handleCancel={() => setIsModalVisible(false)}
          handleAddDependency={handleAddDependency}
          questionDependencies={questionDependencies}
          questions={questions}
          handleQuestionDependencyChange={handleQuestionDependencyChange}
          handleQuestionDependencyAnswerChange={
            handleQuestionDependencyAnswerChange
          }
          handleDependencySave={handleDependencySave}
          handleDependencyDelete={handleDependencyDelete}
        />
        {type === 'multichoice' && (
          <>
            <br />
            <Text>Kiválasztható válaszok száma:</Text>
            <InputNumber
              min={1}
              value={maxAnswerCount}
              onChange={value => setMaxAnswerCount(value)}
            />
          </>
        )}
        <br />
        <Text>Címkék:</Text>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Kérem válaszon"
          onChange={handleTagChange}
          value={questionTags}
          options={tagList}
        />
        <br />
        {type !== 'custom' && (
          <>
            <Checkbox
              checked={random}
              onChange={e => {
                setRandom(e.target.checked);
              }}
            >
              Random válasz sorrend
            </Checkbox>
            <br />
            {random && (
              <>
                <Text>Random limit:</Text>
                <InputNumber
                  min={1}
                  value={randomLimit}
                  onChange={value => setRandomLimit(value)}
                />
              </>
            )}
            <br />
            <Checkbox
              checked={hideresult}
              onChange={e => {
                setHideresult(e.target.checked);
              }}
            >
              Eredmény elrejtése
            </Checkbox>
            <br />
            <Checkbox
              checked={webFeatured}
              onChange={e => {
                setWebFeatured(e.target.checked);
              }}
            >
              Weben kiemelt
            </Checkbox>
          </>
        )}
      </div>
      {type !== 'custom' && (
        <div className="answer-container">
          <Title level={4}>Válaszok</Title>
          {!!answers.length && (
            <Button className="answer-copy__btn" onClick={copyAnswers}>
              Válaszok másolása
            </Button>
          )}
          {!!copiedAnswers?.length && (
            <Button className="answer-copy__btn" onClick={injectAnswers}>
              Válaszok beillesztése
            </Button>
          )}
          {answers.map((answer, idx) => (
            <React.Fragment key={idx}>
              <AnswerItem
                answer={answer}
                questionType={type}
                handleChange={answerChange}
                handleRemove={answerRemove}
                handleOrder={answerOrder}
                answers={answers}
              />
              <Divider />
            </React.Fragment>
          ))}
          <Button
            type="danger"
            onClick={() =>
              setAnswers([...answers, { content: '', order: answers.length }])
            }
          >
            <PlusOutlined /> Válasz hozzáadása
          </Button>
        </div>
      )}
      <div className="btn-container">
        <Button type="primary" onClick={submitQuestion} loading={loading}>
          Mentés
        </Button>
      </div>
    </Drawer>
  );
};

export default QuestionDrawer;
