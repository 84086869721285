import React, { useContext, useState } from 'react';
import { Layout, Card, message } from 'antd';
import { withRouter } from 'react-router';
import { apiPost } from '../../utils/api';
import { LOGIN } from '../../constants/apiPath';
import LoginForm from '../../components/login-form/login-form.component';
import logo from '../../assets/opinio-logo.png';

import {
  store as userStore,
  USER_AUTHENTICATED,
} from '../../providers/userProvider';

import './login.page.style.css';

const { Header, Footer, Content } = Layout;

const LoginPage = ({ history }) => {
  const { dispatch } = useContext(userStore);
  const [loginInProgress, setLoginInProgress] = useState(false);

  const onSubmit = async ({ email, password }) => {
    try {
      setLoginInProgress(true);
      const data = await apiPost({ url: LOGIN, data: { email, password } });
      setLoginInProgress(false);
      const { token } = data;
      localStorage.setItem('opinio_token', token);
      dispatch({ type: USER_AUTHENTICATED });
      history.push('/dashboard/survey');
    } catch (err) {
      setLoginInProgress(false);
      message.error('Érvénytelen email vagy jelszó');
    }
  };
  return (
    <Layout className="login-page">
      <Header className="login-page__header"></Header>
      <Content className="login-page__content">
        <Card
          title=""
          className="login-page__content-card"
          bordered={false}
          cover={<img src={logo} />}
        >
          <LoginForm onSubmit={onSubmit} isLoading={loginInProgress} />
        </Card>
      </Content>
      <Footer className="login-page__footer"></Footer>
    </Layout>
  );
};

export default withRouter(LoginPage);
