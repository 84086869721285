import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  FormOutlined,
  TagOutlined,
  GiftOutlined,
  LogoutOutlined,
  FilterOutlined,
  NotificationOutlined,
  DeploymentUnitOutlined,
} from '@ant-design/icons';
import {
  Switch,
  Route,
  useRouteMatch,
  Link,
  useLocation,
} from 'react-router-dom';
import UserListPage from './user-list/user-list.page.jsx';
import UserDetailPage from './user-detail/user-detail.page.jsx';
import SurveyListPage from './survey-list/survey-list.page';
import SurveyDetailPage from './survey-detail/survey-detail.page';
import TagListPage from './tag-list/tag-list.page';
import GiftListPage from './gift-list/gift-list.page';
import FilterDetailPage from './filter-detail/filter-detail.page';
import FilterListPage from './filter-list/filter-list.page';
import TagDetailPage from './tag-detail/tag-detail.page';
import GiftDetailPage from './gift-detail/gift-detail.page';
import PushPage from './push/push.page';
import PointPage from './point/point.page';

import { apiPost } from '../../utils/api';
import { LOGOUT } from '../../constants/apiPath';

import logo from '../../assets/opinio-logo.png';

import './dashboard.page.style.css';

const { Header, Sider, Content } = Layout;

const DashboardPage = () => {
  const [collapsed, setCollapsed] = useState(true);
  let { path } = useRouteMatch();
  const [collapsedWidth, setCollapsedWidth] = useState(0);
  let location = useLocation();
  let defaultKey = null;

  if (location.pathname.indexOf('survey') !== -1) {
    defaultKey = 'survey';
  } else if (location.pathname.indexOf('gift') !== -1) {
    defaultKey = 'gift';
  } else if (location.pathname.indexOf('tag') !== -1) {
    defaultKey = 'tag';
  } else if (location.pathname.indexOf('users') !== -1) {
    defaultKey = 'users';
  } else if (location.pathname.indexOf('filters') !== -1) {
    defaultKey = 'filters';
  } else if (location.pathname.indexOf('push') !== -1) {
    defaultKey = 'push';
  } else if (location.pathname.indexOf('point') !== -1) {
    defaultKey = 'point';
  } else {
    defaultKey = 'dashboard';
  }
  const logout = async () => {
    await apiPost({ url: LOGOUT, data: {}, showNotificaton: false });
    localStorage.removeItem('opinio_token');
  };
  return (
    <Layout className="dashboard-layout">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onBreakpoint={broken => {
          if (!broken) {
            setCollapsedWidth(80);
          }
        }}
        breakpoint="xs"
        collapsedWidth={collapsedWidth}
      >
        <div className="logo">
          <img alt="opinio-logo" src={logo}></img>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[defaultKey]}>
          <Menu.Item key="survey">
            <Link to={'/dashboard/survey'}>
              <FormOutlined />
              <span>Kérdőívek</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="gift">
            <Link to={'/dashboard/gift'}>
              <GiftOutlined />
              <span>Ajándékok</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="tag">
            <Link to={'/dashboard/tag'}>
              <TagOutlined />
              <span>Címkék</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="users">
            <Link to={'/dashboard/users'}>
              <UserOutlined />
              <span>Felhasználók</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="filters">
            <Link to={'/dashboard/filters'}>
              <FilterOutlined />
              <span>Szűrők</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="push">
            <Link to={'/dashboard/push'}>
              <NotificationOutlined />
              <span>Push üzenet</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="point">
            <Link to={'/dashboard/point'}>
              <DeploymentUnitOutlined />
              <span>Csoportos pont adás</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="logout" onClick={logout}>
            <Link to={'/'}>
              <LogoutOutlined />
              <span>Kijelentkezés</span>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-header">
          {collapsed ? (
            <MenuUnfoldOutlined
              className="trigger"
              onClick={() => setCollapsed(!collapsed)}
            />
          ) : (
            <MenuFoldOutlined
              className="trigger"
              onClick={() => setCollapsed(!collapsed)}
            />
          )}
        </Header>
        <Content
          className="site-layout-content"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          <Switch>
            <Route exact path={`${path}`}>
              <SurveyListPage />
            </Route>
            <Route exact path={`${path}/survey`}>
              <SurveyListPage />
            </Route>
            <Route exact path={`${path}/tag`}>
              <TagListPage />
            </Route>
            <Route exact path={`${path}/gift`}>
              <GiftListPage />
            </Route>
            <Route exact path={`${path}/gift/new`}>
              <GiftDetailPage />
            </Route>
            <Route exact path={`${path}/gift/:uuid`}>
              <GiftDetailPage />
            </Route>
            <Route exact path={`${path}/tag/new`}>
              <TagDetailPage />
            </Route>
            <Route exact path={`${path}/tag/:uuid`}>
              <TagDetailPage />
            </Route>
            <Route exact path={`${path}/survey/new/:type`}>
              <SurveyDetailPage key={'/survey/new'} />
            </Route>
            <Route exact path={`${path}/survey/:uuid`}>
              <SurveyDetailPage key={'/survey/uuid'} />
            </Route>
            <Route exact path={`${path}/users`}>
              <UserListPage />
            </Route>
            <Route exact path={`${path}/users/new`}>
              <UserDetailPage />
            </Route>
            <Route exact path={`${path}/users/:uuid`}>
              <UserDetailPage />
            </Route>
            <Route exact path={`${path}/filters/new`}>
              <FilterDetailPage />
            </Route>
            <Route exact path={`${path}/filters/:uuid`}>
              <FilterDetailPage />
            </Route>
            <Route exact path={`${path}/filters`}>
              <FilterListPage />
            </Route>
            <Route exact path={`${path}/push`}>
              <PushPage />
            </Route>
            <Route exact path={`${path}/point`}>
              <PointPage />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardPage;
