import React, { useState } from 'react';
import { Layout, Card, message, Result } from 'antd';
import { withRouter } from 'react-router';
import { apiPut } from '../../utils/api';
import { PASSWORD_RESET } from '../../constants/apiPath';
import PasswordResetForm from '../../components/password-reset-form/password-reset-form.component';
import logo from '../../assets/opinio-logo.png';

import './password-reset.page.style.css';

const { Header, Footer, Content } = Layout;

const PasswordResetPage = ({ match }) => {
  const [passwordResetInProgress, setPasswordResetInProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const uuid = match.params.uuid;
  const onSubmit = async ({ password }) => {
    try {
      setPasswordResetInProgress(true);
      await apiPut({
        url: PASSWORD_RESET,
        data: {
          resetToken: uuid,
          password: password,
        },
      });
      setSuccess(true);
      setPasswordResetInProgress(false);
    } catch (err) {
      setPasswordResetInProgress(false);
      message.error('Jelszó változtatás sikertelen');
    }
  };
  return (
    <Layout className="password-reset-page">
      <Header className="password-reset-page__header"></Header>
      <Content className="password-reset-page__content">
        <Card
          title=""
          className="password-reset-page__content-card"
          bordered={false}
          cover={<img src={logo} />}
        >
          {!success && (
            <PasswordResetForm
              onSubmit={onSubmit}
              isLoading={passwordResetInProgress}
            />
          )}
          {success && (
            <Result status="success" title="Sikeres jelszóváltoztatás!" />
          )}
        </Card>
      </Content>
      <Footer className="password-reset__footer"></Footer>
    </Layout>
  );
};

export default withRouter(PasswordResetPage);
