import React from 'react';
import {
  Typography,
  Divider,
  Progress,
  Statistic,
  Button,
  Popconfirm,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  UpOutlined,
  DownOutlined,
  ShoppingOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  ArrowRightOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import './question-info-card.style.css';

const { Title, Text } = Typography;

const QuestionInfoCard = ({
  question = {
    answers: [],
  },
  questionList = [],
  onEditClick = () => {},
  onDeleteClick = () => {},
  onOrderClick = () => {},
  onJumpClick = () => {},
  onSurveyCloseClick = () => {},
}) => {
  return (
    <div className="question-info-card" data-testid="question-info-card">
      <div className="question-info-card__content">
        <div className="question-info-card_text">
          <div className="question-info-card_title">
            <Title level={4}>
              {question.content && question.content.replace('\n', '')}
            </Title>
          </div>
          <div className="question-info-card_description">
            <div className="question-info-card_vote">
              {question.answers
                .sort((a, b) => a.order - b.order)
                .map(answer => (
                  <div key={answer.id}>
                    <div>
                      <Text>{answer.content}</Text>
                      {question.type !== 'ordered' && (
                        <>
                          <Button
                            danger
                            type="primary"
                            style={{ marginLeft: '10px' }}
                            icon={<CloseOutlined />}
                            onClick={() =>
                              onSurveyCloseClick({
                                answer,
                              })
                            }
                            size="small"
                          />
                          <Button
                            style={{ marginLeft: '5px' }}
                            icon={<ArrowRightOutlined />}
                            onClick={() =>
                              onJumpClick({
                                answer,
                              })
                            }
                            size="small"
                          />
                          {answer.next_question_id && (
                            <Text style={{ marginLeft: '10px' }}>
                              {
                                questionList.find(
                                  question =>
                                    question.id === answer.next_question_id
                                ).content
                              }
                            </Text>
                          )}
                          {answer.jumpToEnd && (
                            <Text style={{ marginLeft: '10px' }}>
                              Kérdőív lezárása
                            </Text>
                          )}
                        </>
                      )}
                    </div>
                    {question.type !== 'ordered' && (
                      <Progress
                        percent={
                          answer.counter === 0
                            ? 0
                            : (
                                (answer.counter / question.counter) *
                                100
                              ).toFixed(0)
                        }
                      />
                    )}
                  </div>
                ))}
            </div>
            <div className="question-info-card_stat">
              <Statistic
                title="Válaszadók száma"
                value={question.counter}
                prefix={<UserOutlined />}
              />
              <Statistic
                title="Pont érték"
                value={question.point}
                prefix={<ShoppingOutlined />}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="question-info-card_footer">
        <Button icon={<EditOutlined />} onClick={() => onEditClick(question)} />
        <Divider type="vertical" />
        <Button
          icon={<UpOutlined />}
          onClick={() => onOrderClick(question, 'up')}
        />
        <Divider type="vertical" />
        <Button
          icon={<DownOutlined />}
          onClick={() => onOrderClick(question, 'down')}
        />
        <Divider type="vertical" />
        <Popconfirm
          title="Biztos a törlésben"
          onConfirm={() => onDeleteClick(question)}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
          <Button type="danger" icon={<DeleteOutlined />} />
        </Popconfirm>
      </div>
    </div>
  );
};

export default QuestionInfoCard;
